import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const defaultKeywords = [`tiffany`, `caban`, `cabán`, `queens distric attorney`, `da`];
function SEO({ description, lang, meta, keywords, title, image, url }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <>
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title || data.site.siteMetadata.title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
              meta={[
                {
                  name: `description`,
                  content: metaDescription,
                },
                {
                  property: `og:title`,
                  content: title,
                },
                {
                  property: `og:url`,
                  content: url || "https://www.cabanforqueens.com",
                },
                {
                  property: `og:image`,
                  content: image ? `https://www.cabanforqueens.com${image}`:'https://www.cabanforqueens.com/images/fb.png',
                },
                {
                  property: `og:description`,
                  content: metaDescription,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.author,
                },
                {
                  name: `twitter:title`,
                  content: title,
                },
                {
                  name: `twitter:description`,
                  content: metaDescription,
                },
                keywords && keywords.length > 0 ? {
                        name: `keywords`,
                        content: defaultKeywords.concat(keywords).join(`, `),
                      }
                : defaultKeywords.join(', ')
              ]
                .concat(meta)}
            />
          </>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
